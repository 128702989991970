/* eslint-disable no-unused-vars */
import React, { memo, useId } from 'react';

// * MUI
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, CircularProgress, LinearProgress } from '@mui/material';

// * Components
import InfiniteScroll from 'react-infinite-scroll-component';
import ContentHeader from '../ContentHeader';
import { SectionPaper } from '../styledComponents';
import { CallsList, MobileCallsList, CallsListHeader } from '../CallsList';

// * Hooks
import { useMe } from '../../../AppContextProvider';

// * Interfaces
import type { Call, Topic } from '../../types';

const LoadingComponent = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <CircularProgress />
  </Box>
);

interface ScrollComponentProps {
  calls: Call[]
  hasMoreCalls: boolean
  enableSearch: boolean
  randomId: string
  searchTerm: string
  action: 'add' | 'delete'
  loadMoreCalls: () => Promise<void>
  handleActionClick: (call: Call) => void
}

const ScrollComponent: React.FC<ScrollComponentProps> = memo(({
  calls,
  hasMoreCalls,
  enableSearch,
  randomId,
  searchTerm,
  action,
  loadMoreCalls,
  handleActionClick,
}) => {
  const { me } = useMe();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const ListComponent = isMobile ? MobileCallsList : CallsList;

  return (
    <InfiniteScroll
      // @ts-ignore
      pageStart={0}
      dataLength={calls.length}
      next={loadMoreCalls}
      hasMore={hasMoreCalls}
      loader={<LinearProgress />}
      scrollableTarget={randomId}
    >
      <ListComponent
        calls={calls}
        searchTerm={searchTerm}
        action={action}
        handleActionClick={handleActionClick}
        enableHighlighting={enableSearch}
        userTimezone={me.timezone}
      />
    </InfiniteScroll>
  );
});

interface ContentSectionPropsBase {
  topic: Topic | undefined
  calls: Call[]
  hasMoreCalls: boolean
  loadMoreCalls: () => Promise<void>
  loading?: boolean
  title: string
  action: 'add' | 'delete'
  handleActionClick: (call: Call) => void
  searchTerm: string
}

interface ContentSectionPropsWithSearch extends ContentSectionPropsBase {
  enableSearch: true
  onSearch: (query: string) => void
}

interface ContentSectionPropsWithoutSearch extends ContentSectionPropsBase {
  enableSearch: false
}

type ContentSectionProps =
  | ContentSectionPropsWithSearch
  | ContentSectionPropsWithoutSearch

const ContentSection: React.FC<ContentSectionProps> = props => {
  const {
    topic,
    calls,
    hasMoreCalls,
    loadMoreCalls,
    loading,
    title,
    action,
    handleActionClick,
    enableSearch,
    searchTerm,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const onSearchFn = (): ((query: string) => void) => {
    if ('onSearch' in props) {
      const { onSearch } = props;
      return onSearch;
    }

    return () => {};
  };

  const randomId = useId();

  const scrollProps = {
    topic,
    calls,
    hasMoreCalls,
    loadMoreCalls,
    randomId,
    searchTerm,
    action,
    enableSearch,
    handleActionClick,
  };

  return (
    <SectionPaper elevation={0} square>
      <ContentHeader
        title={title}
        length={calls.length}
        enableSearch={enableSearch}
        onSearch={onSearchFn()}
        disabled={!topic}
      />
      <Box
        id={randomId}
        sx={{
          overflowY: 'auto',
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {isMobile ? (
          <ScrollComponent {...scrollProps} />
        ) : (
          <>
            <CallsListHeader />
            {loading ? <LoadingComponent /> : <ScrollComponent {...scrollProps} />}
          </>
        )}
      </Box>
    </SectionPaper>
  );
};

export default ContentSection;
