import * as React from 'react';
import { useMutation } from '@apollo/client';
import {
  Box, FormGroup, FormControlLabel,
} from '@mui/material';
import {
  CustomCheckbox, CustomTooltip,
} from './styledComponents';
import { DISABLE_AUDIO_IN_RESEARCH_PORTAL_MUTATION } from '../../queries/transcript/transcript';

const tooltipText = 'If is checked the play buttons and download MP3 will not be shown in Research Portal.';

type DisableAudioProps = {
  transcriptUuid: string,
  disableAudio: boolean;
};

const DisableAudio = ({
  transcriptUuid, disableAudio,
}: DisableAudioProps) => {
  const [disableAudioInResearch] = useMutation(DISABLE_AUDIO_IN_RESEARCH_PORTAL_MUTATION);

  const handleCheckboxChange = (isChecked: boolean) => {
    disableAudioInResearch({
      variables: {
        transcriptUuid,
        disableAudio: isChecked,
      },
    });
  };

  return (
    <form>
      <FormGroup>
        <CustomTooltip
          title={tooltipText}
          arrow
        >
          <Box display="flex" flexDirection="column">
            <FormControlLabel
              sx={{ marginLeft: 0, marginRight: '24px' }}
              control={(
                <CustomCheckbox
                  name="disableAudioCheckbox"
                  onChange={e => {
                    handleCheckboxChange(e.target.checked);
                  }}
                  defaultChecked={disableAudio}
                />
              )}
              label={(
                <Box display="flex" alignItems="center" sx={{ fontWeight: 400, whiteSpace: 'nowrap' }}>
                  Disable Audio
                </Box>
              )}
            />
          </Box>
        </CustomTooltip>
      </FormGroup>
    </form>
  );
};

export default DisableAudio;
