/* eslint-disable import/order */
import React, { useEffect } from 'react';

// * MUI
import {
  Box, Grid, Typography, Divider, FormControlLabel,
} from '@mui/material';

// * Components
import HighlightItemCard from '../../components/Cards/HighlightItemCard/index';
import HighlightedParagraph from '../../components/HighlightedParagraph';
import CompleteReviewWithNoHighlights from '../../components/CompleteReviewWithNoHighlights';
import {
  CustomCheckbox, HighlightDot, HighlightStatus, MainContainer, ScrollContainer,
} from './styledComponents';

// * Hooks & Utils
import { useForm, Controller, FieldValues } from 'react-hook-form';
import { useQuery } from '@apollo/client';

// * Queries
import { MY_ACCOUNT_SETTINGS } from '../../queries/auth/account';

// * Interfaces
import type { HighlightType } from '../../shared/types/transcript';

type HighlightsProps = {
  highlights: HighlightType[];
  analysts: string[];
  experts: string[];
  selectedHighlight: HighlightType | null;
  setSelectedHighlight: React.Dispatch<React.SetStateAction<HighlightType | null>>;
  pendingCount?: number;
  resolvedCount?: number;
  aiResolvedCount?: number;
  setHighlightsFilterData: any;
  loading: boolean;
  transcriptionStatus: string;
  transcriptId: string;
  reviewUuid: string;
  selectedTab: string;
  isFileAText: boolean;
  callTopic?: string;
  audio: HTMLAudioElement | null;
  isPlayingMain: boolean;
  setIsPlayingMain: React.Dispatch<React.SetStateAction<boolean>>;
  isPlayingParagraph: boolean;
  setIsPlayingParagraph: React.Dispatch<React.SetStateAction<boolean>>;
  setFirstCompletedUuid: any;
  isDocument: boolean;
};

const Highlights = ({
  highlights,
  analysts,
  experts,
  selectedHighlight,
  setSelectedHighlight,
  pendingCount,
  resolvedCount,
  aiResolvedCount,
  setHighlightsFilterData,
  loading,
  transcriptionStatus,
  transcriptId,
  reviewUuid,
  selectedTab,
  isFileAText,
  callTopic,
  audio,
  isPlayingMain,
  setIsPlayingMain,
  isPlayingParagraph,
  setIsPlayingParagraph,
  setFirstCompletedUuid,
  isDocument,
}: HighlightsProps) => {
  const { control, watch, setValue } = useForm();
  const formData = watch();

  const getTrueKeys = (data: FieldValues) => (
    Object.keys(data).filter(key => data[key] === true).map(key => (key === 'aiResolved' ? 'ai resolved' : key))
  );
  const { data: { myAccountSettings: settings = {} } = {} } = useQuery(MY_ACCOUNT_SETTINGS);

  useEffect(() => {
    setHighlightsFilterData({ statuses: getTrueKeys(formData) });
    // eslint-disable-next-line
  }, [formData.pending, formData.resolved, formData.aiResolved]);

  useEffect(() => {
    setValue('aiResolved', selectedTab === 'reviewed');
    setValue('resolved', selectedTab === 'reviewed');
    setValue('pending', true);
    // eslint-disable-next-line
  }, [selectedTab]);

  const renderNoHighlights = () => {
    if (pendingCount === 0 && resolvedCount === 0 && selectedTab === 'completed'
      && (formData.aiResolved === false || aiResolvedCount === 0)
      && settings.reviewTranscriptsWithNoHighlights && transcriptionStatus === 'Completed') {
      return (
        <CompleteReviewWithNoHighlights
          transcriptId={transcriptId}
          reviewUuid={reviewUuid}
          setFirstCompletedUuid={setFirstCompletedUuid}
        />
      );
    }

    return (
      <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
        <Typography variant="h6" color="colors.gray04">
          {loading ? 'Loading...' : 'No Items of Interest'}
        </Typography>
      </Box>
    );
  };

  return (
    <MainContainer>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle1" color="colors.grey10">
          Items of Interest (
          {highlights.length}
          )
        </Typography>
      </Box>
      {selectedTab === 'completed' && (
        <>
          <Divider flexItem sx={{ margin: '12px 0', borderColor: 'colors.gray03' }} />
          <form>
            <Grid container spacing={0} sx={{ ...(transcriptionStatus !== 'Completed' && { opacity: 0.5 }) }}>
              <Grid item xs={6}>
                <Box display="flex">
                  <FormControlLabel
                    disabled={transcriptionStatus !== 'Completed'}
                    sx={{ marginLeft: '-2px' }}
                    control={(
                      <Controller
                        name="pending"
                        control={control}
                        defaultValue={true as boolean}
                        render={({
                          field: {
                            onChange, onBlur, value, ref,
                          },
                        }) => (
                          <CustomCheckbox
                            disabled={transcriptionStatus !== 'Completed'}
                            onBlur={onBlur}
                            onChange={onChange}
                            checked={value}
                            inputRef={ref}
                          />
                        )}
                      />
                    )}
                    label={(
                      <Box display="flex" alignItems="center">
                        <HighlightDot sx={{ backgroundColor: 'colors.warning04' }} />
                        <HighlightStatus variant="body1" color="colors.gray08">
                          Pending (
                          {pendingCount}
                          )
                        </HighlightStatus>
                      </Box>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex">
                  <FormControlLabel
                    disabled={transcriptionStatus !== 'Completed'}
                    sx={{ marginLeft: '-2px' }}
                    control={(
                      <Controller
                        name="resolved"
                        control={control}
                        render={({
                          field: {
                            onChange, onBlur, value, ref,
                          },
                        }) => (
                          <CustomCheckbox
                            disabled={transcriptionStatus !== 'Completed'}
                            onBlur={onBlur}
                            onChange={onChange}
                            checked={value}
                            inputRef={ref}
                          />
                        )}
                      />
                    )}
                    label={(
                      <Box display="flex" alignItems="center">
                        <HighlightDot sx={{ backgroundColor: 'colors.success03' }} />
                        <HighlightStatus variant="body1" color="colors.gray08">
                          Resolved (
                          {resolvedCount}
                          )
                        </HighlightStatus>
                      </Box>
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </>
      )}
      <Divider flexItem sx={{ margin: '12px 0', borderColor: 'colors.gray03' }} />
      {(highlights.length !== 0 && !loading && transcriptionStatus === 'Completed') ? (
        <ScrollContainer>
          <Box display="flex" flexDirection="column">
            {!!highlights && highlights.map(highlight => (
              <HighlightItemCard
                key={highlight.uuid}
                highlight={highlight}
                selected={highlight.uuid === selectedHighlight?.uuid}
                setSelectedHighlight={setSelectedHighlight}
                pendingFilter={formData.pending}
                resolvedFilter={formData.resolved}
                aiResolvedFilter={formData.aiResolved}
                isFileAText={isFileAText}
                audio={audio}
                analysts={analysts}
                experts={experts}
                callTopic={callTopic}
                isPlayingMain={isPlayingMain}
                setIsPlayingMain={setIsPlayingMain}
                isPlayingParagraph={isPlayingParagraph}
                setIsPlayingParagraph={setIsPlayingParagraph}
                pendingCount={pendingCount || 0}
                setFirstCompletedUuid={setFirstCompletedUuid}
                disableReassign={selectedTab === 'reviewed'}
                highlightedParagraph={(<HighlightedParagraph highlight={highlight} />)}
                isDocument={isDocument}
              />
            ))}
          </Box>
        </ScrollContainer>
      ) : renderNoHighlights()}
    </MainContainer>
  );
};

Highlights.defaultProps = {
  pendingCount: 0,
  resolvedCount: 0,
  aiResolvedCount: 0,
  callTopic: '',
};

export default Highlights;
