import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Button, IconButton, Box,
  InputAdornment, Stack, TextField, Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import WestRoundedIcon from '@mui/icons-material/WestRounded';
import LoginLogoImg from '../../assets/img/loginLogoImg.png';
import LoginLogoImgWebp from '../../assets/img/loginLogoImg.webp';
import LoginLogoMobImg from '../../assets/img/loginLogoMobImg.png';
import LoginLogoMobImgWebp from '../../assets/img/loginLogoMobImg.webp';
import LoginRingsLeftImg from '../../assets/img/loginRingsLeftImg.png';
import LoginRingsLeftImgWebp from '../../assets/img/loginRingsLeftImg.webp';
import LoginRingsLeftMobImg from '../../assets/img/loginRingsLeftMobImg.png';
import LoginRingsLeftMobImgWebp from '../../assets/img/loginRingsLeftMobImg.webp';
import LoginRingsRightImg from '../../assets/img/loginRingsRightImg.png';
import LoginRingsRightImgWebp from '../../assets/img/loginRingsRightImg.webp';
import LoginRingsRightMobImg from '../../assets/img/loginRingsRightMobImg.png';
import LoginRingsRightMobImgWebp from '../../assets/img/loginRingsRightMobImg.webp';
import LoginCirclesImg from '../../assets/img/loginСirсlesImg.png';
import LoginCirclesImgWebp from '../../assets/img/loginСirсlesImg.webp';
import LoginCirclesMobImg from '../../assets/img/loginCirclesMobImg.png';
import LoginCirclesMobImgWebp from '../../assets/img/loginCirclesMobImg.webp';
import paths from '../../paths/paths';
import { REQUEST_2FA_CODE, LOGIN_MUTATION } from '../../queries/auth/account';
import palette from '../../theme/palette';

const ScreenBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
}));

const CardBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '55px 29px',
  marginTop: '32px',
  maxWidth: '410px',
  borderRadius: '15px',
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    padding: '40px 20px 0',
    marginTop: 0,
    maxWidth: '360px',
  },
}));

const BackBtn = styled(Button)({
  '&:hover': {
    textDecoration: 'none',
    '& p': {
      color: palette.colors.gray06,
    },
  },
});

const SignInPassword = () => {
  const { email } = useParams<{ email: string }>();
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordValidated, setIsPasswordValidated] = useState(false);
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [formErrors, setFormErrors] = useState<string | undefined>(undefined);

  const [request2fa] = useMutation(REQUEST_2FA_CODE, {
    onCompleted: () => {
      setIsPasswordValidated(true);
      setFormErrors(undefined);
      toast.success('Verification code sent to your email. Please check your inbox.');
    },
    onError: error => {
      setFormErrors(error.message);
    },
  });

  const [validateTwoFA] = useMutation(LOGIN_MUTATION, {
    onCompleted: data => {
      const { token } = data.tokenAuth2fa;
      if (token) {
        localStorage.setItem('token', token);
        window.location.href = paths.home.reverse();
      }
    },
    onError: error => {
      setFormErrors(error.message);
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handlePasswordSubmit = async () => {
    if (email) {
      await request2fa({
        variables: {
          email,
          password,
        },
      });
    }
  };

  const handleTwoFASubmit = async () => {
    if (email) {
      await validateTwoFA({
        variables: {
          email,
          otpToken: code,
        },
      });
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ScreenBox sx={{ backgroundColor: 'background.paper' }}>
      <Box display="flex" justifyContent="center" alignItems="center" height="100%" flexGrow={1}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img
            src={isMobile ? LoginLogoMobImg : LoginLogoImg}
            srcSet={isMobile ? LoginLogoMobImgWebp : LoginLogoImgWebp}
            alt="logo"
            loading="lazy"
            width={isMobile ? 197 : 266}
            height={isMobile ? 59 : 80}
          />
          <CardBox>
            <Box display="flex" mb={isMobile ? '20px' : '35px'}>
              <BackBtn
                variant="text"
                href={paths.signInEmail.pattern}
                sx={{ minWidth: '50px' }}
              >
                <Box display="flex" alignItems="center">
                  <WestRoundedIcon sx={{ color: 'colors.gray08' }} />
                  <Typography
                    variant="body1"
                    color="colors.gray04"
                    sx={{ marginLeft: '12px', textTransform: 'lowercase', transition: 'color 250ms' }}
                  >
                    {email}
                  </Typography>
                </Box>
              </BackBtn>
            </Box>
            <Typography variant="h4" color="colors.gray10" textAlign="center" mb={isMobile ? '18px' : '36px'}>
              {isPasswordValidated ? 'Enter 2FA Code' : 'Enter password'}
            </Typography>
            {!isPasswordValidated ? (
              <Stack alignItems="center" justifyContent="center">
                <TextField
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  error={!!formErrors}
                  helperText={formErrors}
                  type={showPassword ? 'text' : 'password'}
                  variant="outlined"
                  placeholder="Password"
                  sx={{ width: isMobile ? 320 : 352, marginBottom: isMobile ? '12px' : '20px' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  onClick={handlePasswordSubmit}
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: '16px', marginBottom: '16px', width: isMobile ? 320 : 352 }}
                >
                  Continue
                </Button>
                <Button variant="text" href={paths.forgotPassword.pattern}>
                  Forgot password?
                </Button>
              </Stack>
            ) : (
              <Stack alignItems="center" justifyContent="center">
                <TextField
                  value={code}
                  onChange={e => setCode(e.target.value)}
                  error={!!formErrors}
                  helperText={formErrors}
                  variant="outlined"
                  placeholder="Two Factor Code"
                  sx={{ width: isMobile ? 320 : 352, marginBottom: isMobile ? '12px' : '20px' }}
                />
                <Button
                  onClick={handleTwoFASubmit}
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: '16px', width: isMobile ? 320 : 352 }}
                >
                  Verify Code
                </Button>
              </Stack>
            )}
          </CardBox>
        </Box>
        <Box display="flex" position="absolute" top={0} left={0}>
          <img
            src={isMobile ? LoginRingsLeftMobImg : LoginRingsLeftImg}
            srcSet={isMobile ? LoginRingsLeftMobImgWebp : LoginRingsLeftImgWebp}
            alt="img1"
            loading="lazy"
            width={isMobile ? 95 : 614}
            height={isMobile ? 95 : 614}
          />
        </Box>
        <Box display="flex" position="absolute" bottom={0} right={0}>
          <img
            src={isMobile ? LoginRingsRightMobImg : LoginRingsRightImg}
            srcSet={isMobile ? LoginRingsRightMobImgWebp : LoginRingsRightImgWebp}
            alt="img2"
            loading="lazy"
            width={isMobile ? 113 : 614}
            height={isMobile ? 113 : 614}
          />
        </Box>
        <Box display="flex" position="absolute" bottom={isMobile ? 20 : 80} left={isMobile ? 20 : 100}>
          <img
            src={isMobile ? LoginCirclesMobImg : LoginCirclesImg}
            srcSet={isMobile ? LoginCirclesMobImgWebp : LoginCirclesImgWebp}
            alt="img3"
            loading="lazy"
            width={isMobile ? 103 : 230}
            height={isMobile ? 62 : 140}
          />
        </Box>
      </Box>
    </ScreenBox>
  );
};

export default SignInPassword;
