import * as React from 'react';
import { isEmpty } from 'lodash';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  AppBar, Box, Toolbar, Typography, IconButton,
  Avatar, MenuItem, Divider,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
// import AltHeaderLogoImg from '../../assets/img/altHeaderLogoImg.png';
// import AltHeaderLogoImgWebp from '../../assets/img/altHeaderLogoImg.webp';
import SettingIcon from '../../assets/icons/settingIcon.png';
import SettingIconWebp from '../../assets/icons/settingIcon.webp';
import SettingWhiteIcon from '../../assets/icons/settingWhiteIcon.png';
import SettingWhiteIconWebp from '../../assets/icons/settingWhiteIcon.webp';
import LogoutIcon from '../../assets/icons/logoutIcon.png';
import LogoutIconWebp from '../../assets/icons/logoutIcon.webp';
import LogoutWhiteIcon from '../../assets/icons/logoutWhiteIcon.png';
import LogoutWhiteIconWebp from '../../assets/icons/logoutWhiteIcon.webp';
import DefaultFullLogoImg from '../../assets/img/defaultFullLogo.svg';
import { ReactComponent as ContentIcon } from '../../assets/icons/contentIcon.svg';
// import { ReactComponent as Building4Icon } from '../../assets/icons/building4Icon.svg';
import { ReactComponent as Note2Icon } from '../../assets/icons/note2Icon.svg';
// import { ReactComponent as BookSavedIcon } from '../../assets/icons/bookSavedIcon.svg';
import {
  StyledMenu, DropdownButton, NavigationBtn, MobNavigationBtn, Drawer,
} from './styledComponents';
import paths from '../../paths/paths';
import palette from '../../theme/palette';
import { useMe } from '../AppContextProvider';
import PortalSwitcher from '../PortalSwitcher';
import AccountSwitcher from '../AccountSwitcher';

const HeaderBarRP = () => {
  const { me, error } = useMe();
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();

  const handleButtonClick = (url: string) => {
    navigate(url);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleHomePage = () => {
    navigate(paths.home.reverse());
  };

  const handleProfileSettings = () => {
    handleCloseUserMenu();
    navigate(paths.userSettings.pattern);
  };

  const handleSignOut = () => {
    handleCloseUserMenu();
    localStorage.clear();
    navigate(paths.signInEmail.reverse());
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        height: '72px',
        justifyContent: 'center',
        backgroundColor: palette.white,
        borderBottom: '1px solid',
        borderBottomColor: 'colors.gray03',
        boxShadow: '0px 6px 20px 0px rgba(176, 183, 195, 0.20)',
        padding: isMobile ? '0 16px' : '0 32px',
      }}
    >
      <Toolbar
        disableGutters
        sx={{ justifyContent: 'space-between' }}
      >
        {isMobile ? (
          <Box display="flex" alignItems="center" width="100%">
            <IconButton
              onClick={toggleDrawer(true)}
              aria-label="menu"
              sx={{ padding: 0 }}
            >
              <MenuRoundedIcon sx={{ color: palette.colors.gray10 }} />
            </IconButton>
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              pr="24px"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width={150}
                height={64}
              >
                <img
                  src={me.accountFullLogo || DefaultFullLogoImg}
                  alt="logo"
                  loading="lazy"
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                  /*
                  width="136"
                  height="42"
                  */
                />
              </Box>
            </Box>
            <Drawer
              anchor="left"
              open={open}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              disableSwipeToOpen={false}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <Box p="24px 30px" sx={{ borderBottom: '1px solid', borderBottomColor: palette.colors.gray05 }}>
                  <Box display="flex">
                    <ArrowBackIosNewRoundedIcon onClick={toggleDrawer(false)} sx={{ color: palette.white }} />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" flexGrow={1} p="24px 30px">
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Avatar
                      alt={(!isEmpty(me) && !error) ? `${me.firstName} ${me.lastName}` : 'User'}
                      src={me.avatar}
                      sx={{ height: 85, width: 85, marginBottom: '12px' }}
                    />
                    <Box mb="4px" color={palette.white} sx={{ fontSize: '20px', fontWeight: 800, lineHeight: '20px' }}>
                      {(!isEmpty(me) && !error) ? `${me.firstName} ${me.lastName}` : 'User'}
                    </Box>
                    <Box
                      mb="20px"
                      color={palette.primary.main}
                      sx={{ fontSize: '14px', fontWeight: 500, lineHeight: '16px' }}
                    >
                      Research Portal
                    </Box>
                  </Box>
                  {!isEmpty(me) && (
                    <AccountSwitcher
                      isAcaReviewer={me.isAcaReviewer}
                      account={me.account}
                      accounts={me.accounts}
                    />
                  )}
                  <Divider flexItem sx={{ margin: '12px 0 20px', borderColor: 'white' }} />
                  <MobNavigationBtn
                    onClick={() => handleButtonClick(paths.home.reverse())}
                    className={location.pathname.includes('/calls') ? 'selected' : ''}
                  >
                    <ContentIcon />
                    <Typography variant="subtitle1" color="white" ml="16px">
                      Content
                    </Typography>
                  </MobNavigationBtn>
                  <Divider flexItem sx={{ margin: '20px 0', borderColor: 'white' }} />
                  {/*
                  <MobNavigationBtn
                    onClick={() => handleButtonClick('/companies')}
                    className={location.pathname.includes('/companies') ? 'selected' : ''}
                  >
                    <Building4Icon />
                    <Typography variant="subtitle1" color="white" ml="16px">
                      Companies
                    </Typography>
                  </MobNavigationBtn>
                  <Divider flexItem sx={{ margin: '20px 0', borderColor: 'white' }} />
                  */}
                  <MobNavigationBtn
                    onClick={() => handleButtonClick(paths.topics.reverse())}
                    className={location.pathname.includes('/topics') ? 'selected' : ''}
                  >
                    <Note2Icon />
                    <Typography variant="subtitle1" color="white" ml="16px">
                      Topics
                    </Typography>
                  </MobNavigationBtn>
                  <Divider flexItem sx={{ margin: '20px 0', borderColor: 'white' }} />
                  {/*
                  <MobNavigationBtn
                    onClick={() => handleButtonClick('/theses')}
                    className={location.pathname.includes('/theses') ? 'selected' : ''}
                  >
                    <BookSavedIcon />
                    <Typography variant="subtitle1" color="white" ml="16px">
                      Theses
                    </Typography>
                  </MobNavigationBtn>
                  <Divider flexItem sx={{ margin: '20px 0', borderColor: 'white' }} />
                  */}
                  <Box display="flex" flexDirection="column" mt="auto">
                    {me.allowSwitchRolesOnFrontend && <PortalSwitcher isResearch />}
                    <Box display="flex" alignItems="center" mb="24px" onClick={handleProfileSettings}>
                      <img
                        src={SettingWhiteIcon}
                        srcSet={SettingWhiteIconWebp}
                        alt="icon1"
                        loading="lazy"
                        width="24"
                        height="24"
                      />
                      <Typography variant="subtitle1" color="white" ml="16px">
                        User Settings
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" onClick={handleSignOut}>
                      <img
                        src={LogoutWhiteIcon}
                        srcSet={LogoutWhiteIconWebp}
                        alt="icon4"
                        loading="lazy"
                        width="24"
                        height="24"
                      />
                      <Typography variant="subtitle1" color="white" ml="16px">
                        Sign Out
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Drawer>
          </Box>
        ) : (
          <>
            <Box display="flex" alignItems="center">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width={150}
                height={64}
                sx={{ cursor: 'pointer' }}
                onClick={handleHomePage}
              >
                <img
                  src={me.accountFullLogo || DefaultFullLogoImg}
                  alt="logo"
                  loading="lazy"
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                  /*
                  width="136"
                  height="42"
                  */
                />
              </Box>
              <Divider orientation="vertical" flexItem sx={{ margin: '15px 32px', borderColor: palette.colors.gray03 }} />
              <Box minHeight={72} display="flex">
                <NavigationBtn
                  onClick={() => handleButtonClick(paths.home.reverse())}
                  className={location.pathname.includes('/calls') ? 'selected' : ''}
                  startIcon={<ContentIcon />}
                >
                  Content
                </NavigationBtn>
                {/*
                <NavigationBtn
                  onClick={() => handleButtonClick('/companies')}
                  className={location.pathname.includes('/companies') ? 'selected' : ''}
                  startIcon={<Building4Icon />}
                >
                  Companies
                </NavigationBtn>
                */}
                <NavigationBtn
                  onClick={() => handleButtonClick(paths.topics.reverse())}
                  className={location.pathname.includes('/topics') ? 'selected' : ''}
                  startIcon={<Note2Icon />}
                >
                  Topics
                </NavigationBtn>
                {/*
                <NavigationBtn
                  onClick={() => handleButtonClick('/theses')}
                  className={location.pathname.includes('/theses') ? 'selected' : ''}
                  startIcon={<BookSavedIcon />}
                >
                  Theses
                </NavigationBtn>
                */}
              </Box>
            </Box>
            <Box>
              <DropdownButton
                onClick={handleOpenUserMenu}
                variant="text"
                endIcon={anchorElUser ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              >
                <Box display="flex">
                  <Avatar alt={(!isEmpty(me) && !error) ? `${me.firstName} ${me.lastName}` : 'User'} src={me.avatar} />
                  <Box display="flex" flexDirection="column" alignItems="flex-start" ml="11px">
                    <Box mb="4px" color="colors.gray09" sx={{ fontSize: '16px', fontWeight: 700, lineHeight: '19px' }}>
                      {(!isEmpty(me) && !error) ? `${me.firstName} ${me.lastName}` : 'User'}
                    </Box>
                    <Box sx={{
                      fontSize: '12px', fontWeight: 400, lineHeight: '14px', color: palette.primary.main,
                    }}
                    >
                      Research Portal
                    </Box>
                  </Box>
                </Box>
              </DropdownButton>
              <StyledMenu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {!isEmpty(me) && (
                  <AccountSwitcher
                    isAcaReviewer={me.isAcaReviewer}
                    account={me.account}
                    accounts={me.accounts}
                  />
                )}
                {me.allowSwitchRolesOnFrontend && <PortalSwitcher isResearch />}
                <MenuItem key="userSettings" onClick={handleProfileSettings}>
                  <img
                    src={SettingIcon}
                    srcSet={SettingIconWebp}
                    alt="icon1"
                    loading="lazy"
                    width="24"
                    height="24"
                  />
                  <Typography
                    variant="body1"
                    color="colors.gray08"
                    sx={{ fontSize: '14px', lineHeight: '24px', marginLeft: '4px' }}
                  >
                    User Settings
                  </Typography>
                </MenuItem>
                <MenuItem key="signOut" onClick={handleSignOut}>
                  <img
                    src={LogoutIcon}
                    srcSet={LogoutIconWebp}
                    alt="icon4"
                    loading="lazy"
                    width="24"
                    height="24"
                  />
                  <Typography
                    variant="body1"
                    color="colors.gray08"
                    sx={{ fontSize: '14px', lineHeight: '24px', marginLeft: '4px' }}
                  >
                    Sign Out
                  </Typography>
                </MenuItem>
              </StyledMenu>
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default HeaderBarRP;
