/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

// * MUI
import { Box, Divider } from '@mui/material';

// * Components
import Header from './components/Header';
import Content from './components/Content';
import Footer from './components/Footer';
import { Drawer } from './components/styledComponents';
import DialogConfirmAction from './components/DialogConfirmAction';

// * Interfaces
import type { Call, Topic } from './types';

interface TopicCallsDrawerProps {
  topic: Topic | undefined
  state: boolean
  toggleDrawer: (
    open: boolean
  ) => (
    event: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>
  ) => void
  onDeleteTopic: () => Promise<void>
  onProceed: (_topic: Topic | undefined) => Promise<void>
  onAddCall: (call: Call) => void
  onDeleteCall: (call: Call) => void
  onSaveTopicName: (name: string) => Promise<{ created: boolean }>
  onSearchCalls: (query: string) => void
  validateUniqueName: (name: string) => Promise<boolean>
  loadMoreAvailableCalls: () => Promise<void>
  topicCalls: Call[]
  availableCalls: Call[]
  searchTerm: string
  callsHaveChanged: () => boolean
  deleteTopicLoading: boolean
  proceedLoading: boolean
  topicNameLoading: boolean
  availableCallsLoading: boolean
  hasMoreAvailableCalls: boolean
  onCreateTopic?: (newTopic: Topic) => void
}

const TopicCallsDrawer = ({
  topic,
  state,
  toggleDrawer,
  onDeleteTopic,
  onProceed,
  onAddCall,
  onDeleteCall,
  onSaveTopicName,
  validateUniqueName,
  onSearchCalls,
  loadMoreAvailableCalls,
  topicCalls,
  availableCalls,
  searchTerm,
  callsHaveChanged,
  deleteTopicLoading,
  proceedLoading,
  topicNameLoading,
  availableCallsLoading,
  hasMoreAvailableCalls,
  onCreateTopic,
}: TopicCallsDrawerProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [actionType, setActionType] = useState<'delete' | null>(null);

  const contentProps = {
    topic,
    topicCalls: {
      calls: topicCalls,
      onDelete: onDeleteCall,
    },
    availableCalls: {
      calls: availableCalls,
      loading: availableCallsLoading,
      hasMore: hasMoreAvailableCalls,
      loadMore: loadMoreAvailableCalls,
      onAdd: onAddCall,
    },
    search: {
      searchTerm,
      onSearch: onSearchCalls,
    },
  };

  const handleCloseDrawer = (
    event: MouseEvent | TouchEvent | React.BaseSyntheticEvent,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    toggleDrawer(false)({} as React.MouseEvent);
  };

  const handleCloseDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmAction = async () => {
    if (actionType === 'delete') {
      await onDeleteTopic();
      handleCloseDialog();
      toggleDrawer(false)({} as React.MouseEvent);
    }
  };

  const handleDeleteTopic = () => {
    setActionType('delete');
    setOpenConfirmDialog(true);
  };

  const enableProceed = callsHaveChanged();

  const handleOnProceed = async () => {
    await onProceed(topic);
  };

  const confirmDialogProps = {
    openDialog: openConfirmDialog,
    actionLoading: deleteTopicLoading,
    handleCloseDialog,
    handleConfirmAction,
    title: actionType === 'delete' ? 'Delete Topic' : '',
    message:
      actionType === 'delete'
        ? 'Are you sure you want to delete this topic? This action cannot be undone.'
        : '',
  };

  return (
    <Drawer
      anchor="right"
      open={state}
      onClose={handleCloseDrawer}
      onOpen={toggleDrawer(true)}
    >
      <Box display="flex" flexDirection="column" minHeight="100%">
        <Header
          topic={topic}
          loading={topicNameLoading}
          validateUniqueName={validateUniqueName}
          onClose={handleCloseDrawer}
          onSave={onSaveTopicName}
          onCreateTopic={onCreateTopic}
          drawerState={state}
        />
        <Divider flexItem sx={{ borderColor: 'colors.gray03' }} />
        <Content {...contentProps} />
        <Divider flexItem sx={{ borderColor: 'colors.gray03' }} />
        <Footer
          topic={topic}
          onDelete={handleDeleteTopic}
          onClose={handleCloseDrawer}
          onProceed={handleOnProceed}
          enableProceed={enableProceed}
          loadingAction={deleteTopicLoading || proceedLoading}
        />
      </Box>
      <DialogConfirmAction {...confirmDialogProps} />
    </Drawer>
  );
};

TopicCallsDrawer.defaultProps = {
  onCreateTopic: undefined,
};

export default TopicCallsDrawer;
