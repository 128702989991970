import React from 'react';
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
} from 'docx';

// * Hooks
import { useMutation } from '@apollo/client';

// * Components
import DownloadFile from '../DownloadFile';

// * Icons
import { ReactComponent as DownloadPDFIcon } from '../../../assets/icons/downloadPDF.svg';

// * Mutations
import { GET_DOCX_MUTATION } from '../../../queries/transcript/transcript';

const DownloadDOCX = ({
  transcriptUuid,
  fileName,
}: {
  transcriptUuid: string
  fileName: string
}) => {
  const [getDocxTranscript] = useMutation(GET_DOCX_MUTATION);

  const handleDownloadDocx = async () => {
    try {
      const response = await getDocxTranscript({ variables: { transcriptUuid } });
      const { docString } = response.data.getDocxTranscript;

      const doc = new Document({
        sections: [
          {
            properties: {},
            children: [
              new Paragraph({
                children: [
                  new TextRun(docString),
                ],
              }),
            ],
          },
        ],
      });

      const buffer = await Packer.toBlob(doc);
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}.docx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating DOCX:', error);
    }
  };

  return (
    <DownloadFile
      onDownload={handleDownloadDocx}
      IconComponent={DownloadPDFIcon}
      fileType="docx"
    />
  );
};

export default DownloadDOCX;
