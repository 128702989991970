/* eslint-disable no-unused-vars */
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

// * Utils
import { useLazyQuery, useMutation } from '@apollo/client';
import { AppContext } from '../AppContextProvider';

// * Interfaces
import type {
  CreateTopicType,
  TopicExistsType,
  UpdateTopicNameMutationType,
} from '../../shared/types/topics';
import type { FilterAccountCallsBySearchAndTopicType } from '../../shared/types/calls';
import type { Call, Topic } from './types';

// * Queries & Mutations
import {
  CHECK_TOPIC_EXISTS,
  CREATE_TOPIC,
  DELETE_TOPIC,
  UPDATE_TOPIC_CALLS,
  UPDATE_TOPIC_NAME,
} from '../../queries/topics';
import { FILTER_ACCOUNT_CALLS_BY_SEARCH_AND_TOPIC } from '../../queries/calls/filterAccountCallsBySearchAndTopic';
import { mapAccountCalls } from './utils';
import paths from '../../paths/paths';

const QUERY_INITIAL_VALUE = { results: [], totalCount: 0 };
const ITEMS_PER_PAGE = 20;

export const useTopicOperations = (
  mode: 'create' | 'edit',
  initialTopic: Topic | undefined,
  updateTopic: (_topic: Topic | undefined) => void,
) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [availableCalls, setAvailableCalls] = useState<Call[]>([]);
  const [topicCalls, setTopicCalls] = useState<Call[]>(
    initialTopic?.calls || [],
  );
  const [drawerState, setDrawerState] = useState(false);

  const { refetchMyTopics, refetchCurrentTopic } = useContext(AppContext);
  const navigate = useNavigate();

  const [createTopic, { loading: createTopicLoading }] = useMutation<CreateTopicType>(CREATE_TOPIC);
  const [deleteTopic, { loading: deleteTopicLoading }] = useMutation<{
    deleteTopic: boolean
  }>(DELETE_TOPIC);
  const [checkTopicExists, { loading: checkTopicExistsLoading }] = useLazyQuery<TopicExistsType>(CHECK_TOPIC_EXISTS, {
    fetchPolicy: 'network-only',
  });
  const [updateTopicName,
    { loading: updateTopicNameLoading }] = useMutation<UpdateTopicNameMutationType>(UPDATE_TOPIC_NAME);
  const [updateTopicCalls, { loading: updateTopicCallsLoading }] = useMutation<{
    updateTopicCalls: boolean
  }>(UPDATE_TOPIC_CALLS);

  const [
    fetchAvailableCalls,
    {
      data: { filterAccountCallsBySearchAndTopic = QUERY_INITIAL_VALUE } = {},
      loading: availableCallsLoading,
      fetchMore: fetchMoreAvailableCalls,
    },
  ] = useLazyQuery<FilterAccountCallsBySearchAndTopicType>(
    FILTER_ACCOUNT_CALLS_BY_SEARCH_AND_TOPIC,
    {
      variables: { first: ITEMS_PER_PAGE, skip: 0 },
      onCompleted: data => {
        const filteredAccountCalls = mapAccountCalls(
          data.filterAccountCallsBySearchAndTopic.results,
        ).filter(call => !topicCalls.some(c => c.uuid === call.uuid));

        setAvailableCalls(filteredAccountCalls);
      },
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (initialTopic) {
      setTopicCalls(initialTopic.calls || []);
    }
  }, [initialTopic]);

  const clearState = useCallback((clearTopic?: (_topic?: Topic) => void) => {
    setAvailableCalls([]);
    setSearchTerm('');
    setTopicCalls([]);
    clearTopic?.(undefined);
  }, []);

  const handleFetchAvailableCalls = async (
    topicUuid: string,
    search?: string,
  ) => {
    await fetchAvailableCalls({ variables: { topicUuid, searchTerm: search } });
  };

  const toggleDrawer = (open: boolean, topicUuid?: string) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event
        && event.type === 'keydown'
        && ((event as React.KeyboardEvent).key === 'Tab'
          || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    if (open && topicUuid) {
      handleFetchAvailableCalls(topicUuid);
    }

    setDrawerState(open);
  };

  const callsHaveChanged = useCallback(() => {
    const availableUuids = new Set(availableCalls.map(call => call.uuid));
    const topicUuids = new Set(topicCalls.map(call => call.uuid));
    return (
      availableUuids.size !== topicUuids.size
      || [...availableUuids].some(uuid => !topicUuids.has(uuid))
    );
  }, [availableCalls, topicCalls]);

  const validateUniqueName = async (name: string): Promise<boolean> => {
    const topicExists = await checkTopicExists({ variables: { name } });
    return !topicExists.data?.topicExists;
  };

  const loadMoreAvailableCalls = async () => {
    const newSkip = filterAccountCallsBySearchAndTopic.results.length;
    await fetchMoreAvailableCalls({
      variables: { skip: newSkip },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          filterAccountCallsBySearchAndTopic: {
            ...prev.filterAccountCallsBySearchAndTopic,
            results: [
              ...prev.filterAccountCallsBySearchAndTopic.results,
              ...fetchMoreResult.filterAccountCallsBySearchAndTopic.results,
            ],
          },
        };
      },
    });
  };

  const onAddCall = (call: Call) => {
    setAvailableCalls(prevAvailableCalls => prevAvailableCalls.filter(c => c.uuid !== call.uuid));
    setTopicCalls(prevTopicCalls => [...prevTopicCalls, call]);
  };

  const onDeleteCall = (call: Call) => {
    setTopicCalls(prevTopicCalls => prevTopicCalls.filter(c => c.uuid !== call.uuid));
    setAvailableCalls(prevAvailableCalls => [...prevAvailableCalls, call]);
  };

  const onSaveTopicName = async (
    name: string,
  ): Promise<{ created: boolean }> => {
    if (initialTopic) {
      const updatedTopic = await updateTopicName({
        variables: { uuid: initialTopic.uuid, name },
      });
      // @ts-ignore
      await refetchMyTopics();
      // @ts-ignore
      await refetchCurrentTopic();
      if (updatedTopic.data) {
        updateTopic({
          ...initialTopic,
          name: updatedTopic.data.updateTopicName.topic.name,
        });
      }
      await handleFetchAvailableCalls(initialTopic.uuid);
      return { created: false };
    }

    const newTopic = await createTopic({ variables: { name } });
    if (newTopic.data) {
      updateTopic({
        ...newTopic.data.createTopic,
        jsonSummary: '{}',
        calls: [],
        status: 'created',
        relatedCompanies: [],
      });
      await handleFetchAvailableCalls(newTopic.data.createTopic.uuid);
      // @ts-ignore
      await refetchMyTopics();
      navigate(paths.topics.reverse());
    }
    return { created: true };
  };

  const onDeleteTopic = async () => {
    if (!initialTopic) return;

    const topicDeleted = await deleteTopic({
      variables: { uuid: initialTopic.uuid },
    });
    if (topicDeleted.data?.deleteTopic) {
      setTopicCalls([]);
      setAvailableCalls([]);
      // @ts-ignore
      await refetchMyTopics();
      navigate(paths.topics.reverse());
    }
  };

  const handleProceed = async () => {
    if (!initialTopic) return;

    const updatedTopic = await updateTopicCalls({
      variables: {
        uuid: initialTopic.uuid,
        callUuids: topicCalls.map(c => c.uuid),
      },
    });
    if (updatedTopic.data?.updateTopicCalls) {
      updateTopic({ ...initialTopic, calls: topicCalls });
    }
  };

  const onSearchCalls = (query: string) => {
    if (!initialTopic) return;

    setSearchTerm(query);
    handleFetchAvailableCalls(initialTopic.uuid, query);
  };

  const { results, totalCount } = filterAccountCallsBySearchAndTopic;

  const topicNameLoading = mode === 'create'
    ? updateTopicNameLoading
        || checkTopicExistsLoading
        || createTopicLoading
        || availableCallsLoading
    : updateTopicNameLoading || checkTopicExistsLoading;

  return {
    validateUniqueName,
    searchTerm,
    loadMoreAvailableCalls,
    setTopicCalls,
    availableCalls: availableCalls.filter(
      call => !topicCalls.some(c => c.uuid === call.uuid),
    ),
    topicCalls,
    onSaveTopicName,
    onAddCall,
    onDeleteCall,
    onDeleteTopic,
    handleProceed,
    onSearchCalls,
    hasMoreAvailableCalls:
      availableCalls.length > 0 ? totalCount > results.length : false,
    availableCallsLoading,
    deleteTopicLoading,
    proceedLoading: updateTopicCallsLoading,
    topicNameLoading,
    callsHaveChanged,
    state: drawerState,
    toggleDrawer,
    clearState,
  };
};

export default { useTopicOperations };
