/* eslint-disable import/order */
import React, { useContext } from 'react';

// * MUI
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Menu, MenuItem } from '@mui/material';

// * Components
import {
  ChangeAccountContainer, AccountBtn, Avatar, SelectedTypography,
  AccountAccordion, AccountSummary, AccountDetails,
} from './styledComponents';

// * Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DefaultAvatarLogo from '../../assets/img/defaultAvatarLogo.svg';

// * Hooks & Utils
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import client from '../../client';
import palette from '../../theme/palette';
import { AppContext, useMe } from '../AppContextProvider';

// * Queries
import { ALL_USERS_QUERY } from '../../queries/auth/account';
import { CHANGE_USER_ACCOUNT_MUTATION } from '../../queries/auth/changeUserAccountMutation';
import { ALL_TICKERS_QUERY } from '../../queries/transcript/allTickers';

// * Interfaces
import type { AccountType } from '../../shared/types/user';

interface AccountSwitcherProps {
  isAcaReviewer: boolean;
  account: AccountType;
  accounts: AccountType[];
}

const AccountSwitcher = ({
  isAcaReviewer,
  account,
  accounts,
}: AccountSwitcherProps) => {
  const { refetchMe } = useMe();
  const { refetchScheduledCalls } = useContext(AppContext);
  const navigate = useNavigate();
  const [changeUserAccount] = useMutation(CHANGE_USER_ACCOUNT_MUTATION);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeAccount = async (accountUuid: string) => {
    await changeUserAccount({ variables: { accountUuid } });
    await refetchMe();
    await refetchScheduledCalls?.();
    await client.refetchQueries({ include: [ALL_USERS_QUERY, ALL_TICKERS_QUERY] });
    navigate('/');
  };

  const sortedAccounts = [...accounts].sort((a, b) => a.name.localeCompare(b.name));

  if (isMobile) {
    return (
      <Box>
        {(sortedAccounts.length > 1 && !isAcaReviewer) ? (
          <AccountAccordion>
            <AccountSummary
              expandIcon={<ExpandMoreIcon sx={{ color: palette.white }} />}
              aria-controls="accounts-content"
              id="accounts-header"
            >
              <Box display="flex" alignItems="center" textTransform="capitalize">
                <Avatar alt={account.name} src={account.settings.avatarLogo || DefaultAvatarLogo} />
                <SelectedTypography variant="body1" color={palette.white} ml="4px" mr="4px">
                  {account.name}
                </SelectedTypography>
              </Box>
            </AccountSummary>
            <AccountDetails>
              {sortedAccounts.map(item => (
                <Box
                  key={item.uuid}
                  onClick={() => handleChangeAccount(item.uuid)}
                  className={item.uuid === account.uuid ? 'disabled' : ''}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '12px',
                    minWidth: 230,
                  }}
                >
                  <Box display="flex" alignItems="center" textTransform="capitalize">
                    <Avatar alt={item.name} src={item.settings.avatarLogo || DefaultAvatarLogo} />
                    <SelectedTypography variant="body1" color={palette.white} ml="4px" mr="4px">
                      {item.name}
                    </SelectedTypography>
                  </Box>
                  {item.uuid === account.uuid && (
                    <CheckCircleRoundedIcon sx={{ fontSize: '16px', color: palette.primary.main }} />
                  )}
                </Box>
              ))}
            </AccountDetails>
          </AccountAccordion>
        ) : (
          <ChangeAccountContainer>
            <Box display="flex" alignItems="center" textTransform="capitalize">
              <Avatar alt={account.name} src={account.settings.avatarLogo || DefaultAvatarLogo} />
              <SelectedTypography variant="body1" color={palette.white} ml="4px" mr="4px">
                {account.name}
              </SelectedTypography>
            </Box>
          </ChangeAccountContainer>
        )}
      </Box>
    );
  }

  return (
    <ChangeAccountContainer>
      {(sortedAccounts.length > 1 && !isAcaReviewer) ? (
        <>
          <AccountBtn
            id="account-button"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            endIcon={<ExpandMoreIcon sx={{ transform: 'rotate(-90deg)' }} />}
          >
            <Box display="flex" alignItems="center">
              <Avatar alt={account.name} src={account.settings.avatarLogo || DefaultAvatarLogo} />
              <SelectedTypography variant="body1" color="secondary.main" mr="4px">
                {account.name}
              </SelectedTypography>
            </Box>
          </AccountBtn>
          <Menu
            id="account-menu"
            aria-labelledby="account-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{ marginLeft: '-20px' }}
          >
            {sortedAccounts.map(item => (
              <MenuItem
                key={item.uuid}
                value={item.uuid}
                onClick={() => handleChangeAccount(item.uuid)}
                disabled={item.uuid === account.uuid}
                sx={{
                  padding: '12px 16px',
                  minWidth: 230,
                  justifyContent: 'space-between',
                  '&.Mui-disabled': {
                    opacity: 1,
                  },
                }}
              >
                <Box display="flex" alignItems="center" textTransform="capitalize">
                  <Avatar alt={item.name} src={item.settings.avatarLogo || DefaultAvatarLogo} />
                  <SelectedTypography variant="body1" color="secondary.main" mr="4px">
                    {item.name}
                  </SelectedTypography>
                </Box>
                {item.uuid === account.uuid && (
                  <CheckCircleRoundedIcon sx={{ fontSize: '16px', color: palette.colors.gray05 }} />
                )}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        <Box display="flex" alignItems="center" textTransform="capitalize">
          <Avatar alt={account.name} src={account.settings.avatarLogo || DefaultAvatarLogo} />
          <SelectedTypography variant="body1" color="secondary.main" mr="4px">
            {account.name}
          </SelectedTypography>
        </Box>
      )}
    </ChangeAccountContainer>
  );
};

export default AccountSwitcher;
