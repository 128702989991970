import {
  styled, Box, Drawer, IconButton, Divider, Tabs, Typography,
} from '@mui/material';
import palette from '../../../theme/palette';

const drawerWidth = 459;
const headerHeight = 72;

export const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  marginTop: `${headerHeight}px`,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  maxWidth: '100vw',
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    maxWidth: `calc(100vw - ${drawerWidth}px)`,
  }),
}));

export const DrawerContainer = styled(Drawer)({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    backgroundColor: '#F9F9F9',
    borderRightColor: palette.colors.gray03,
    padding: '104px 32px 0',
    boxSizing: 'border-box',
    overflowY: 'unset',
  },
});

export const DrawerMobile = styled(Drawer)({
  width: '100%',
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: '100%',
    backgroundColor: '#F9F9F9',
    padding: '104px 20px 0',
    boxSizing: 'border-box',
    overflowY: 'unset',
  },
});

export const HeaderContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '22px 18px',
  borderBottom: '1px solid',
  borderBottomColor: palette.colors.gray03,
});

export const HeaderMobile = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 12px',
  borderBottom: '1px solid',
  borderBottomColor: palette.colors.gray03,
  backgroundColor: palette.colors.gray01,
});

export const ScrollContainer = styled(Box)({
  overflowY: 'auto',
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const DrawerHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 0,
  paddingBottom: '12px',
  borderBottom: '1px solid',
  borderBottomColor: palette.colors.gray03,
  marginBottom: '4px',
});

export const DrawerButton = styled(IconButton)({
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

export const VerticalDivider = styled(Divider)({
  height: '51px',
  margin: '0 12px',
  borderColor: palette.colors.gray03,
});

export const MainContainer = styled(Box)({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  height: '100%',
});

export const StickyBox = styled(Box)({
  display: 'none',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'absolute',
  backgroundColor: palette.white,
  width: 'calc(100% + 7px)',
  zIndex: 1,
  padding: '12px 24px 12px 32px',
  marginLeft: '-7px',
  boxShadow: '0px 3px 2px 0px rgba(65, 65, 65, 0.14)',
});

export const AudioSmallBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid',
  color: palette.primary.main,
  borderColor: palette.primary.main,
  borderRadius: '24px',
  padding: '12px',
  height: '48px',
  marginRight: '4px',
});

export const DrawerTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTab-root': {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: 0,
    padding: '16px 32px',
    color: palette.colors.gray07,
    [theme.breakpoints.down('md')]: {
      padding: '16px 24px',
    },
    '&.Mui-selected': {
      color: palette.primary.main,
    },
  },
  '& .MuiTabs-indicator': {
    height: '4px',
  },
}));

export const ContentTabs = styled(Tabs)({
  '& .MuiTab-root': {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: 0,
    padding: '16px 6px',
    color: palette.colors.gray07,
    '&.Mui-selected': {
      color: palette.primary.main,
    },
    '&:nth-child(odd)': {
      maxWidth: 120,
    },
  },
  '& .MuiTabs-indicator': {
    height: '4px',
  },
});

export const HighlightDot = styled(Box)({
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  marginRight: '8px',
});

export const HighlightStatus = styled(Typography)({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  marginLeft: '8px',
});

export const callsSummaryA11yProps = (index: number) => ({
  id: `calls-summary-tab-${index}`,
  'aria-controls': `calls-summary-tabpanel-${index}`,
});

export const topicsSummaryA11yProps = (index: number) => ({
  id: `topics-summary-tab-${index}`,
  'aria-controls': `topics-summary-tabpanel-${index}`,
});

export const contentA11yProps = (index: number) => ({
  id: `content-tab-${index}`,
  'aria-controls': `content-tabpanel-${index}`,
});
