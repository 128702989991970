import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material/styles';
import { useMutation } from '@apollo/client';
import palette from '../../theme/palette';
import { ReactComponent as CompleteReviewWithNoHighlightsImg } from '../../assets/img/completeReviewWithNoHighlightsImg.svg';
import { AppContext } from '../AppContextProvider';
import { COMPLETE_COMPLIANCE_REVIEW_MUTATION, TRANSCRIPT_QUERY } from '../../queries/transcript/transcript';

const CompleteBtn = styled(Button)({
  padding: '12px 16px',
  borderRadius: '6px',
  borderColor: palette.primary.main,
  fontWeight: 600,
  '& .MuiButton-startIcon svg': {
    fontSize: '24px',
  },
  '&:hover': {
    backgroundColor: palette.primary.main,
    color: palette.white,
  },
});

type CompleteReviewWithNoHighlightsProps = {
  transcriptId: string;
  reviewUuid: string;
  setFirstCompletedUuid: any;
};

const CompleteReviewWithNoHighlights = ({
  transcriptId,
  reviewUuid,
  setFirstCompletedUuid,
}: CompleteReviewWithNoHighlightsProps) => {
  const { refetchCompletedCalls, refetchReviewedCalls } = React.useContext(AppContext);
  const [completeReview] = useMutation(COMPLETE_COMPLIANCE_REVIEW_MUTATION, {
    onCompleted: () => {
      if (refetchCompletedCalls && refetchReviewedCalls) {
        refetchCompletedCalls();
        refetchReviewedCalls();
      }
      setFirstCompletedUuid();
    },
    refetchQueries: [
      { query: TRANSCRIPT_QUERY, variables: { uuid: transcriptId } },
    ],
  });

  const handleCompleteReview = () => {
    completeReview({ variables: { reviewUuid } });
  };

  return (
    <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
      <Box display="flex" flexDirection="column" alignItems="center">
        <CompleteReviewWithNoHighlightsImg />
        <Typography
          variant="subtitle2"
          color="colors.gray10"
          align="center"
          sx={{ fontWeight: 400, marginTop: '21px' }}
        >
          There are no items of interest for this call.
        </Typography>
        <Typography
          variant="subtitle2"
          color="colors.gray10"
          align="center"
          sx={{ fontWeight: 400, marginBottom: '16px' }}
        >
          Press the Complete Review button to move this call to the Reviewed tab.
        </Typography>
        <CompleteBtn
          variant="outlined"
          startIcon={<CheckCircleIcon />}
          onClick={handleCompleteReview}
        >
          Complete Review
        </CompleteBtn>
      </Box>
    </Box>
  );
};

export default CompleteReviewWithNoHighlights;
